<template>
  <div class="food-detail">
    <NavbarItem />
    <div class="container">
      <div class="row mt-5">
        <div class="col">
          <nav aria-label="breadcrumb" style="margin-top:100px;">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/foods">Foods</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Foods Detail
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-6">
          <img :src="'img/' + product.gambar" class="img-fluid shadow" />
        </div>
        <div class="col-md-6">
          <h2>{{ product.nama }}</h2>
          <hr />
          <h4>
            Harga : <strong>Rp. {{ product.harga }}</strong>
          </h4>
          <form class="mt-4" v-on:submit.prevent>
            <!-- <input type="hidden" v-model="pesan.product_id">  -->
            <div class="form-group">
              <label>Jumlah</label>
              <input
                type="number"
                class="form-control"
                v-model="pesan.jumlah_pemesanan"
              />
            </div>
            <div class="form-group">
              <label>Keterangan</label>
              <input
                type="text"
                placeholder="Ket: pedas,manis,sedang .."
                class="form-control" autocomplete="off"
                v-model="pesan.keterangan"
                />
            </div>
            <button class="btn btn-success" @click="pesanan">
              <b-icon-bag></b-icon-bag> Pesan
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarItem from "@/components/Navbar.vue";
import axios from "axios";

export default {
  name: "FoodDetail",
  components: {
    NavbarItem,
  },

  data() {
    return {
      product: {},
      pesan: {
        //jumlah_pemesanan:'',
        //keterangan:''
      },
    };
  },

  methods: {
    setproduct(data) {
      this.product = data;
    },
    pesanan() {
      if (this.pesan.jumlah_pemesanan > 0 ) {
         this.pesan.product_id = this.product.id;
        //  this.pesan.keterangan = this.product.nama;
        //this.pesan.products = this.product;
        axios
          //.post("http://localhost:3000/keranjangs", this.pesan)
          // .post("http://localhost:8000/keranjangInsert", this.pesan)
           .post("https://vueback.buatwebmurah.my.id/keranjangInsert", this.pesan)
          .then(() => {
            //redirect  
             this.$router.push({path: "/keranjang"})
            
            this.$toast.success("Berhasil di tambahkan", {
              position: "top-right",
              type: "success",
              
              duration: 3000,
              dismissible: true,
            });
          })
          .catch((error) => console.log(error));
      } else {
        this.$toast.error("Gagal di tambahkan", {
          position: "top-right",
          type: "error",
          duration: 3000,
          dismissible: true,
        });
      }
    },
  },

  mounted() {
    axios
       //.get("http://localhost:3000/products/" + this.$route.params.id)
      //.get("http://localhost:8000/productsDetail/" + this.$route.params.id)
       .get("https://vueback.buatwebmurah.my.id/productsDetail/" + this.$route.params.id)
      .then((response) => this.setproduct(response.data))
      .catch((error) => console.log(error));
  },
};
</script>

<style>
</style>