import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FoodsView from '../views/FoodsView'
import FoodsDetail from '../views/FoodDetailView'
import Keranjang from '../views/KeranjangView'
import PesananSukses from '../views/PesananSuksesView'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pesanan-sukses',
    name: 'pesananSukses',
    component: PesananSukses
  },
  {
    path: '/keranjang',
    name: 'keranjang',
    component: Keranjang
  },
  {
    path: '/foods',
    name: 'foods',
    component: FoodsView
  },
  {
    path: '/foods/:id',
    name: 'FoodsDetail',
    component: FoodsDetail
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
