<template>
   <!-- Page Content -->
    <div class="page-content">
        <div class="container bottom-content">
			<div class="title-bar offer-box">
				<span class="title mb-0">Categories</span>
			</div>
			<div class="row catagore-bx g-4">
				<div class="col-4 text-center">
					<router-link to="/foods">
						<div class="dz-media media-60">
							<img :src="'/img/nasi-goreng-telor.jpg'" alt="image">
						</div>
						<span>Sarapan</span>
					</router-link>	
				</div>
				<div class="col-4 text-center">
					<router-link to="/foods">
						<div class="dz-media media-60">
							<img :src="'/img/mie-ayam-bakso.jpg'" alt="image">
						</div>
						<span>Desserts</span>
					</router-link>
				</div>
				<div class="col-4 text-center">
					<router-link to="/foods">
						<div class="dz-media media-60">
							<img :src="'/img/mie-goreng.jpg'" alt="image">
						</div>
						<span>Ikan & Seafood</span>
					</router-link>
				</div>
				<div class="col-4 text-center">
					<router-link to="/foods">
						<div class="dz-media media-60">
							<img :src="'/img/pangsit.jpg'" alt="image">
						</div>
						<span>Minuman</span>
					</router-link>
				</div>
				<div class="col-4 text-center">
					<router-link to="/foods">
						<div class="dz-media media-60">
							<img :src="'/img/kentang-goreng.jpg'" alt="image">
						</div>
						<span>Snacks</span>
					</router-link>
				</div>
				<div class="col-4 text-center">
					<router-link to="/foods">
						<div class="dz-media media-60">
							<img :src="'/img/nasi-rames.jpg'" alt="image">
						</div>
						<span>Lunch</span>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: "MenuItem",
};
</script>

<style>
</style>