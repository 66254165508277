<template>
<div>
    
	<!-- Preloader -->
	<!-- <div id="preloader">
		<div class="loader">
			<div class="load-circle"><div></div><div></div></div>
		</div>
	</div> -->
    <!-- Preloader end-->
	
	<!-- Header -->
		<header class="header style-4 bg-img header-fixed">
			<div class="container">
				<div class="header-content">
					<div class="location">
						<div class="location-icon">
							<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><path d="M341.476 338.285l49.204-77.056C410.516 233.251 421 200.322 421 166 421 74.98 347.139 0 256 0 165.158 0 91 74.832 91 166c0 34.3 10.704 68.091 31.19 96.446l48.332 75.84C118.847 346.227 31 369.892 31 422c0 18.995 12.398 46.065 71.462 67.159C143.704 503.888 198.231 512 256 512c108.025 0 225-30.472 225-90 0-52.117-87.744-75.757-139.524-83.715zm-194.227-92.34a15.57 15.57 0 0 0-.517-.758C129.685 221.735 121 193.941 121 166c0-75.018 60.406-136 135-136 74.439 0 135 61.009 135 136 0 27.986-8.521 54.837-24.646 77.671-1.445 1.906 6.094-9.806-110.354 172.918L147.249 245.945zM256 482c-117.994 0-195-34.683-195-60 0-17.016 39.568-44.995 127.248-55.901l55.102 86.463a15 15 0 0 0 25.298 0l55.101-86.463C411.431 377.005 451 404.984 451 422c0 25.102-76.313 60-195 60zm0-391c-41.355 0-75 33.645-75 75s33.645 75 75 75 75-33.645 75-75-33.645-75-75-75zm0 120c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill="#fff"/></svg> -->
							<b-icon-bag class="text-white"></b-icon-bag>
						</div>
						<div class="location-content">
							<div class="d-flex align-items-center">
								<h5 class="title me-2">Kulineran</h5>
							</div>
							<p>
								Mulai cari Makanan Kesukaanmu ..
							</p>
						</div>
						<a href="javascript:void(0);" class="menu-toggler icon-box-2">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.001 297.001" ><path d="M107.883 0h-85.27C10.144 0 .001 10.143.001 22.612v85.27c0 12.469 10.143 22.612 22.612 22.612h85.27c12.469 0 22.612-10.143 22.612-22.612v-85.27C130.493 10.143 120.352 0 107.883 0zm166.505 0h-85.27c-12.469 0-22.612 10.143-22.612 22.612v85.27c0 12.469 10.143 22.612 22.612 22.612h85.27c12.469 0 22.612-10.143 22.612-22.612v-85.27C297 10.143 286.857 0 274.388 0zM107.883 166.507h-85.27c-12.469 0-22.612 10.142-22.612 22.611v85.27C.001 286.857 10.144 297 22.613 297h85.27c12.469 0 22.612-10.143 22.612-22.612v-85.27c-.002-12.469-10.143-22.611-22.612-22.611zm166.505 0h-85.27c-12.469 0-22.612 10.143-22.612 22.612v85.27c0 12.469 10.143 22.612 22.612 22.612h85.27C286.857 297 297 286.857 297 274.388v-85.27c0-12.469-10.143-22.611-22.612-22.611z"/></svg>
						</a>
					</div>
				</div>
				<div class="search-box style-1">
					<div class="input-group input-radius">
						<span class="input-group-text">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M10.9395 1.9313C5.98074 1.9313 1.94141 5.97063 1.94141 10.9294C1.94141 15.8881 5.98074 19.9353 10.9395 19.9353C13.0575 19.9353 15.0054 19.193 16.5449 17.9606L20.293 21.7067C20.4821 21.888 20.7347 21.988 20.9967 21.9854C21.2587 21.9827 21.5093 21.8775 21.6947 21.6924C21.8801 21.5073 21.9856 21.2569 21.9886 20.9949C21.9917 20.7329 21.892 20.4802 21.7109 20.2908L17.9629 16.5427C19.1963 15.0008 19.9395 13.0498 19.9395 10.9294C19.9395 5.97063 15.8982 1.9313 10.9395 1.9313ZM10.9395 3.93134C14.8173 3.93134 17.9375 7.05153 17.9375 10.9294C17.9375 14.8072 14.8173 17.9352 10.9395 17.9352C7.06162 17.9352 3.94141 14.8072 3.94141 10.9294C3.94141 7.05153 7.06162 3.93134 10.9395 3.93134Z" fill="#7D8FAB"></path>
							</svg>
						</span>
						<input type="text" placeholder="Type product name to search" style="height:auto !important;" class="form-control main-in ps-0 bs-0">
					</div>
				</div>
			</div>
		</header>
	<!-- Header -->
	
	
	<!-- Sidebar -->
    <div class="dark-overlay"></div>
	<div class="sidebar">
		<div class="author-box">
			<div class="dz-media" style="margin-right:0px !important; border:0px !important;">
				<img :src="'/img/bwm.png'" alt="author-image">
			</div>
			<div class="dz-info">
				<h5 class="name">buatwebmurah</h5>
				<span>admin@buatwebmurah.my.id</span>
			</div>
		</div>
		<ul class="nav navbar-nav">	
			<li class="nav-label">Main Menu</li>
			<li>
				<router-link to="/" class="nav-link">
					<span class="dz-icon">
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
							<path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z"/>
						</svg>
					</span>
					<span>Home</span>
				</router-link>
			</li>
			<li>
				<router-link class="nav-link" to="/foods">
					<span class="dz-icon">
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"></path></svg>
					</span>
					<span>Foods Menu</span>
				</router-link>
			</li>
			<li>
				<router-link class="nav-link" to="/keranjang">
					<span class="dz-icon">
						<b-icon-bag></b-icon-bag>	
					</span>
					<span>Cart</span>
				</router-link>
			</li>
			<!-- <li>
				<a class="nav-link" href="chat.html">
					<span class="dz-icon">
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM7 9h10c.55 0 1 .45 1 1s-.45 1-1 1H7c-.55 0-1-.45-1-1s.45-1 1-1zm6 5H7c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm4-6H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1z"></path></svg>
					</span>
					<span>Chat</span>
					<span class="badge badge-circle badge-info">5</span>
				</a>
			</li>
			<li>
				<a class="nav-link" href="welcome.html">
					<span class="dz-icon">
						<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g></g><g><g><path d="M5,5h6c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h6c0.55,0,1-0.45,1-1v0 c0-0.55-0.45-1-1-1H5V5z"/><path d="M20.65,11.65l-2.79-2.79C17.54,8.54,17,8.76,17,9.21V11h-7c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h7v1.79 c0,0.45,0.54,0.67,0.85,0.35l2.79-2.79C20.84,12.16,20.84,11.84,20.65,11.65z"/></g></g></svg>
					</span>
					<span>Logout</span>
				</a>
			</li> -->
            <li class="nav-label">Settings</li>
            <!-- <li class="nav-color" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                <a href="javascript:void(0);" class="nav-link">
                    <span class="dz-icon">                        
                        <svg class="color-plate" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#000000">
							<path d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"/>
						</svg>
                    </span>					
                    <span>Color Theme</span>					
                </a>
            </li> -->
            <li>
                <div class="mode">
                    <span class="dz-icon">
                        <svg class="dark" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g></g><g><g><g><path d="M11.57,2.3c2.38-0.59,4.68-0.27,6.63,0.64c0.35,0.16,0.41,0.64,0.1,0.86C15.7,5.6,14,8.6,14,12s1.7,6.4,4.3,8.2 c0.32,0.22,0.26,0.7-0.09,0.86C16.93,21.66,15.5,22,14,22c-6.05,0-10.85-5.38-9.87-11.6C4.74,6.48,7.72,3.24,11.57,2.3z"/></g></g></g>
						</svg>
                    </span>					
                    <span class="text-dark">Dark Mode</span>
                    <div class="custom-switch">
                        <input type="checkbox" class="switch-input theme-btn" id="toggle-dark-menu">
                        <label class="custom-switch-label" for="toggle-dark-menu"></label>
                    </div>
                </div>
            </li>
		</ul>
		<div class="sidebar-bottom">
			<h6 class="name">Foods Order Apps</h6>
			<span class="ver-info">App Version 1.0</span>
        </div>
    </div>
    </div>


    <!-- Sidebar End -->
<!-- <div>
 
  <b-navbar toggleable="lg" variant="light">
     <b-container>
    <b-navbar-brand href="#">Kulineran</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item><router-link style="color:black;" to="/">Home</router-link></b-nav-item>
        <b-nav-item><router-link style="color:black;" to="/foods">Foods</router-link></b-nav-item>
      
      </b-navbar-nav> -->

      <!-- Right aligned nav items -->
      <!-- <b-navbar-nav class="ml-auto">
          <router-link  class="nav-link" to="/keranjang"> 
                    Keranjang <b-icon-bag></b-icon-bag> <span class="badge badge-success ml-2"> -->
                      <!-- {{ jumlah_pesanans.length }} -->
                     <!-- {{ updatekeranjang ? updatekeranjang.length : jumlah_pesanans.length  }} -->
                    <!-- </span>
                </router-link>
      </b-navbar-nav>
    </b-collapse>
  </b-container>
  </b-navbar>
  
</div> -->

</template>

<script>

import axios from "axios";

export default {
  name: "NavbarItem",

  data(){
    return {
      jumlah_pesanans:[],
    }
  },
  
  props: ['updatekeranjang'],

  methods: {
    setJumlah(data){
      this.jumlah_pesanans = data
    }
  },

  mounted(){
      axios
      //.get("http://localhost:3000/keranjangs/")
    //   .get("http://localhost:8000/keranjangs/")
      .get("https://vueback.buatwebmurah.my.id/keranjangs")
      .then((response) => this.setJumlah(response.data))
      .catch((error) => console.log(error));
  }

  }
</script>

<style>
</style>