<template>
  <div id="app">
    <div class="page-wraper">
    
    <router-view/>
    <FooterItem/>
    </div>
  </div>
</template>

<script>
import FooterItem from "@/components/Footer.vue";

export default {
  components: {
    FooterItem
  }
};
</script>
