<template>
  <div>
    <NavbarItem />
    <div class="container">
      <div class="row" style="margin-top:150px;">
        <h2>Daftar <strong>Makanan</strong></h2>
      </div>

      <div class="row mt-3">
        <div class="col">
          <div class="input-group mb-3">
            
            <input 
              v-model="search"
              @keyup="searchFood"
              type="text"
              class="form-control"
              placeholder="Cari Makanan Kesukaan Anda"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1"><b-icon-search></b-icon-search></span>
          </div>
          </div>
         
        </div>
      </div>
      <div class="row mb-3">
        <div
          class="col-md-4 mt-4"
          v-for="product in products"
          :key="product.id"
        >
          <CardProduct :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarItem from "@/components/Navbar.vue";
import CardProduct from "@/components/CardProduct.vue";
import axios from "axios";

export default {
  name: "FoodsView",
  components: {
    NavbarItem,
    CardProduct,
  },

  data() {
    return {
      products: [],
      search: '',
    };
  },

  methods: {
    setProducts(data) {
      this.products = data;
    },

    searchFood(){
      axios
       //.get("http://localhost:3000/products?q="+this.search)
      // .get("http://localhost:8000/productsCari/"+this.search)
      .get("https://vueback.buatwebmurah.my.id/productsCari/"+this.search)
      .then((response) => this.setProducts(response.data))
      .catch((error) => console.log("Gagal", error));
    }
  },

  mounted() {
    axios
       //.get("http://localhost:3000/products")
      // .get("http://localhost:8000/products")
      .get("https://vueback.buatwebmurah.my.id/products")
      .then((response) => this.setProducts(response.data))
      .catch((error) => console.log("Gagal", error));
  },
};
</script>