<template>
  <div class="pesanan-sukses">
    <Navbar/>
    <div class="container">
        <div class="row justify-content-center mt-5">
            <div class="col text-center" style="margin-top:100px;">
                <img src="../assets/img/eat.svg" class="img-fluid mb-5" width="300">
                <h2><strong>Sukses !!</strong></h2>
                <h4> Pesanan segera di proses </h4>
                
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";

export default {
  name: "PesananSukses",
  components: {
    Navbar,
  },
};
</script>

<style>
</style>