<template>
  <div class="keranjang">
    <NavbarItem :updatekeranjang="keranjangs"/>
    <div class="container">
      <div class="row mt-5">
        <div class="col">
          <nav aria-label="breadcrumb" style="margin-top:100px;">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/foods">Foods</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Cart</li>
            </ol>
          </nav>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h2>keranjang <strong>Saya</strong></h2>

          
          <div class="row mt-4" v-for="(keranjang) in keranjangs" :key="keranjang.kera">
          <div class="col-4">
            <img :src="'/img/' + keranjang.gambar " class="img-fluid shadow" width="200px">
          </div>
          <div class="col-5">
            {{ keranjang.nama }}<br>
            <font color="red"><b>Rp.{{ keranjang.harga }}</b></font><br>
            {{ keranjang.jumlah_pemesanan }} Qty<br>
            <span>{{ keranjang.note ? keranjang.note :"-" }}</span>
          </div>
          <div class="col-3">
             <button class="btn btn-danger" @click="hapusKeranjang(keranjang.kera)"><b-icon-trash></b-icon-trash></button>
          </div>
          <div style="border-bottom:1px solid grey; margin-top:30px;"></div>
          </div>

          <div class="row">
            <div class="pull-right mt-3">
               <strong>Total Harga: </strong><br>
                <strong>Rp.{{ totalharga }}</strong>
            </div>
          </div>

          <!-- <div class="table table-responsive mt-3">
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Photo</th>
                  <th>Makanan</th>
                  <th>Catatan</th>
                  <th>Jumlah</th>
                  <th>harga</th>
                  <th>Total Harga</th>
                  <th><b-icon-trash></b-icon-trash></th>
                </tr>
              </thead>
              <tbody>
                 <tr v-for="(keranjang,index) in keranjangs" :key="keranjang.kera">
                  <td>{{ index+1}}</td>
                  <td><img :src="'/img/' + keranjang.gambar " class="img-fluid shadow" width="200px"></td>
                  <td>{{ keranjang.nama }}</td>
                  <td>{{ keranjang.note ? keranjang.note :"-" }}</td>
                  <td>{{ keranjang.jumlah_pemesanan }}</td>
                  <td>Rp.{{ keranjang.harga }}</td>
                  <td><strong>Rp.{{ keranjang.harga*keranjang.jumlah_pemesanan }}</strong></td>
                  <td>
                    <button class="btn btn-danger" @click="hapusKeranjang(keranjang.kera)"><b-icon-trash></b-icon-trash></button>
                  </td>
                </tr>
                    
                <tr>
                    <td colspan="6" align="right">
                        <strong>Total Harga: </strong>
                    </td>
                    <td>
                        <strong>Rp.{{ totalharga }}</strong>
                    </td>
                    <td></td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </div>
      </div>

    <div class="row justify-content-end">
      <div class="col-md-4">
          <form class="mt-4" v-on:submit.prevent>
            <div class="form-group">
              <label>Nama</label>
              <input
                type="text"
                class="form-control"
                v-model="pesan.nama"
              />
            </div>
            <div class="form-group">
              <label>Nomor Meja</label>
              <input
                type="text"
                class="form-control"
                v-model="pesan.noMeja"
              />
            </div>
            <button class="btn btn-success float-right" @click="checkout">
              <b-icon-bag></b-icon-bag> Pesan
            </button>
          </form>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
import NavbarItem from "@/components/Navbar.vue";
import axios from "axios";

export default {
  nama: "keranjang",
  components: {
    NavbarItem,
  },

  data(){
    return{
        keranjangs:[],
        pesan:{}
    }
  },

  methods:{
    setKeranjangs(data){
        this.keranjangs = data;
    },
    hapusKeranjang(kera){
      axios
      //.delete("http://localhost:3000/keranjangs/"+id)
      // .get("http://localhost:8000/keranjangsDel/"+kera,{
       .get("https://vueback.buatwebmurah.my.id/keranjangsDel/"+kera,{
        // headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        // }
      })
      .then(() => {
         this.$toast.success("Berhasil di hapus", {
              position: "top-right",
              type: "success",
              duration: 3000,
              dismissible: true,
            });

      //update cart
       axios
      //.get("http://localhost:3000/keranjangs")
      // .get("http://localhost:8000/keranjangs")
       .get("https://vueback.buatwebmurah.my.id/keranjangs")
      .then((response) => this.setKeranjangs(response.data))
      .catch((error) => console.log(error));

      })
      .catch((error) => console.log(error));
    },

    checkout(){
      if(this.pesan.nama && this.pesan.noMeja){
        
        //this.pesan.keranjangs = this.keranjangs
        axios
        //.post("http://localhost:3000/pesanans",this.pesan)
        //  .post("http://localhost:8000/checkout",this.pesan)
        .post("https://vueback.buatwebmurah.my.id/checkout",this.pesan)
        .then(() => {

          this.keranjangs.map(function (item){
            return axios
            //.delete("http://localhost:3000/keranjangs/"+ item.id)
            // .delete("http://localhost:8000/keranjangsDel/"+ item.id)
            .delete("https://vueback.buatwebmurah.my.id/keranjangsDel/"+ item.id)
            .catch((error) => console.log(error));
          });

           this.$toast.success("Berhasil di pesan", {
              position: "top-right",
              type: "success",
              duration: 3000,
              dismissible: true,
            });
          this.$router.push({path: "/pesanan-sukses"})
        })
        .catch((error) => console.log(error))
      }else{
          this.$toast.error("Data tidak boleh kosong", {
              position: "top-right",
              type: "error",
              duration: 3000,
              dismissible: true,
            });
      }
    }
  },

  mounted(){
     axios
       //.get("http://localhost:3000/keranjangs")
      // .get("http://localhost:8000/keranjangs") 
       .get("https://vueback.buatwebmurah.my.id/keranjangs") 
      .then((response) => this.setKeranjangs(response.data))
      .catch((error) => console.log(error));
  },

  computed: {
    totalharga(){
        return this.keranjangs.reduce(function(items, data){
            // return items+(data.products.harga*data.jumlah_pemesanan)
             return items+(data.harga*data.jumlah_pemesanan)
        },0)
    }
  }
}
</script>

<style>
</style>