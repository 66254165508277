<template>
  <footer class="mt-5 mb-5">
    <div class="menubar-area style-7 footer-fixed rounded-0">
      <div class="toolbar-inner menubar-nav">
        <router-link to="/" class="nav-link">
          <i class="fa fa-home"></i>
          <span @click="myFunction">Home</span>
        </router-link>
        <router-link to="/foods" class="nav-link">
          <i class="fa fa-solid fa-box"></i>
          <span>Categories</span>
        </router-link>
        <router-link to="/foods/1" class="nav-link">
          <i class="fa fa-solid fa-heart"></i>
          <span>Favorites</span>
        </router-link>
        <router-link to="/keranjang" class="nav-link">
          <i class="fa fa-solid fa-shopping-bag"></i>
          <span>Cart</span>
        </router-link>
        <a class="nav-link">
          <i class="fa fa-solid fa-user"></i>
          <span>Account</span>
        </a>
      </div>
    </div>
    <hr />
    <div class="container">
      <div class="row">
        <div class="col text-center">2023 &copy;copyright Knalpotpatah</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterItem",
  data() {
    return {
      alamat: window.location.href,
      alam: "",
    };
  },
  methods: {
    myFunction() {
      this.alam = this.alamat.substring(27, 4);
    },
  },

  computed: {
    currentRouteName() {
      return this.$route.path;
    },
  },
};
</script>

<style></style>
