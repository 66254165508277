<template>
  <div class="container">
    <div class="d-none d-md-block">
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="d-flex h-100">
            <div class="justify-content-center align-self-center">
              <h2>
                <strong>Delicius Food Menu</strong>
                <h2>for you</h2>
              </h2>
              <p>Ayo segera pilih dan pesan menu favorit kamu</p>
              
              <router-link to="/foods" class="btn btn-lg btn-success">
                <b-icon-arrow-bar-right></b-icon-arrow-bar-right> Pesan
              </router-link>
            </div>
          </div>
        </div>
        <div class="col">
          <img src="../assets/img/hero.svg" width="100%" />
        </div>
      </div>
    </div>

    <!-- mobile -->
    <div class="d-sm-block d-md-none">
      <div class="row mt-4">
         <div class="col-md-6 mb-3">
          <img src="../assets/img/hero.svg" width="100%" />
        </div>
        <div class="col-md-6">
          <div class="d-flex h-100">
            <div class="justify-content-center align-self-center">
              <!-- <h2>
                <strong>Delicius Food Menu</strong>
                <h2>for you</h2>
              </h2>
              <p>Ayo segera pilih dan pesan menu favorit kamu</p> -->
              <MenuItem style="margin-top:-100px;"/>
              <router-link to="/foods" class="btn btn-lg btn-success">
                <b-icon-arrow-bar-right></b-icon-arrow-bar-right> Pesan
              </router-link>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
//import MenuItem from "@/components/Menu.vue";
import MenuItem from "@/components/Menu.vue";

export default {
  name: "HeroItem",
  components:{
    MenuItem
    //MenuItem,
  },
};
</script>

<style>
</style>